import React, { useState } from "react";
import { Spin as Hamburger } from "hamburger-react";
import PropTypes from "prop-types";

import Container from "../utils/Container";
import NavMenu from "../containers/NavMenu";
import NavMenuMobile from "../containers/NavMenuMobile";
import ShadowOnScroll from "../components/ShadowOnScroll";

import logo from "../images/entomoLogo.svg";

const Header = ({ menuItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <ShadowOnScroll className="w-full h-[95px] lg:h-[115px] xl:h-[140px] 2xl:h-[150px] fixed top-0 left-0 right-0 bg-white py-[10px] lg:py-[15px] 2xl:py-[16px] z-10">
        <header className="max-h-[100vh] overflow-y-auto">
          <Container className="py-[10px] 2xl:py-[15px] overflow-x-hidden">
            <div className="flex justify-between items-center">
              <a href="https://entomo.co/">
                <div className="flex flex-col items-end w-[140px] md:w-[148px] xl:w-[300px]">
                  <img src={logo} alt="entomo" />
                  <div className="text-[#666666] text-sm hidden md:block">formerly KPISOFT</div>
                </div>
              </a>
              <div className="hidden lg:block">
                <NavMenu menuItems={menuItems} />
              </div>
              <div className="block lg:hidden">
                <Hamburger
                  direction="right"
                  size={25}
                  onToggle={() => {
                    setIsOpen((prev) => !prev);
                  }}
                />
              </div>
            </div>
          </Container>
          <NavMenuMobile display={isOpen} menuItems={menuItems} className="overflow-y-auto" />
        </header>
      </ShadowOnScroll>
    </>
  );
};

export default Header;

Header.propTypes = {
  menuItems: PropTypes.array,
};

import React from "react";
import { GrLinkedinOption } from "react-icons/gr";
import { ImInstagram, ImFacebook } from "react-icons/im";
import { TiSocialYoutubeCircular } from "react-icons/ti";
import { AiOutlineTwitter } from "react-icons/ai";

const socialMedias = [
  {
    name: "facebook",
    icon: <ImFacebook color="white" fill="white" />,
    link: "https://www.facebook.com/entomo.co",
  },
  {
    name: "twitter",
    icon: <AiOutlineTwitter color="white" fill="white" />,
    link: "https://twitter.com/entomo_co",
  },
  {
    name: "linkedin",
    icon: <GrLinkedinOption color="white" fill="white" />,
    link: "https://www.linkedin.com/company/entomo-inc/",
  },
  {
    name: "youtube",
    icon: <TiSocialYoutubeCircular color="white" fill="white" />,
    link: "https://www.youtube.com/channel/UCmbuyaJf6bddBRwYT7fYjNA",
  },
  {
    name: "instagram",
    icon: <ImInstagram color="white" fill="white" />,
    link: "https://www.instagram.com/entomo.co/",
  },
];

export { socialMedias };

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

export default function ShadowOnScroll({ className, children }) {
  const [showShadow, setShowShadow] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 100) {
          setShowShadow(true);
        } else {
          setShowShadow(false);
        }
      });
    }
  }, []);

  return <div className={`${showShadow ? "shadow-md" : "shadow-none"} ${className}`}>{children}</div>;
}

ShadowOnScroll.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import PropTypes from "prop-types";

const Button = ({ item, index, selected, setSelected }) => {
  return (
    <button
      onClick={() => {
        if (selected.includes(item.name)) {
          let newSelected = selected.slice();
          let index = newSelected.indexOf(item.name);
          newSelected.pop(index);
          setSelected(newSelected);
        } else {
          let newSelected = selected.slice();
          newSelected.push(item.name);
          setSelected(newSelected);
        }
      }}
      className="flex justify-between items-center w-full py-4 text-base font-semibold text-gray-800 text-left"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target={"#collapse" + index}
      aria-expanded="false"
      aria-controls={"collapse" + index}
    >
      {item.url ? <Link to={item.url}>{item.component}</Link> : item.component}
      {item.sub ? (
        <>
          {selected.includes(item.name) ? (
            <FaChevronUp className="ml-1 text-xs" />
          ) : (
            <FaChevronDown className="ml-1 text-xs" />
          )}
        </>
      ) : null}
    </button>
  );
};

// eslint-disable-next-line react/prop-types
export default function NavMenuMobile({ menuItems, display }) {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected([]);
  }, [display]);

  return (
    <>
      <div
        className={
          display ? "h-auto w-full bg-white lg:hidden pl-[24px] pr-[36px] shadow-lg pb-4" : "h-0 overflow-hidden"
        }
      >
        {/* eslint-disable-next-line react/prop-types */}
        {menuItems.map((item, index) => {
          return (
            <div key={item.name}>
              <Button item={item} index={index} selected={selected} setSelected={setSelected} />
              {item.sub && selected.includes(item.name) ? (
                <div className="box-border border-8">
                  <div className="p-4 pb-10">
                    <img src={item.img} className="w-full" alt={item.name} />
                    {item.title ? (
                      <>
                        {item.url ? (
                          <Link to={item.url}>
                            <div className="mt-5 font-semibold text-2xl">{item.title}</div>
                          </Link>
                        ) : (
                          <div className="mt-5 font-semibold text-2xl">{item.title}</div>
                        )}
                      </>
                    ) : null}
                    {item.description ? (
                      <div className="mt-4 font-normal text-base text-[#808080]">{item.description}</div>
                    ) : null}
                    {item.action ? (
                      <Link to={item.action.url || "#"}>
                        <div className="mt-6 m-0 p-0 text-base font-semibold text-[#4285f4]">{item.action.name}</div>
                      </Link>
                    ) : null}
                  </div>
                  <div>
                    {item.sub.map((subItem) => {
                      return (
                        <Link to={subItem.url || "#"} key={subItem.name}>
                          <div className="mb-5 px-4">
                            <div className="font-semibold text-sm">{subItem.name}</div>
                            {subItem.description ? <div className="text-sm">{subItem.description}</div> : null}
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                  <hr />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </>
  );
}

NavMenuMobile.protoTypes = {
  menuItems: PropTypes.array,
  display: PropTypes.string,
};

Button.propTypes = {
  item: PropTypes.shape,
  index: PropTypes.number,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};

import React from "react";
import { socialMedias } from "../src/constants/socialmedia-link";
// import { Link } from "gatsby";
// import * as React from "react";
import { AiFillStar } from "react-icons/ai";

export const getCookie = (name) => {
  // Get name followed by anything except a semicolon
  var cookiestring = RegExp(name + "=[^;]+").exec(document.cookie);
  // Return everything after the equal sign, or an empty string if the cookie name not found
  return decodeURIComponent(cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "");
};

export const getSocialMediaLink = (reqSocialMedia = []) => {
  const filteredSocialmedia = socialMedias.filter((socialmedia) => {
    return reqSocialMedia.includes(socialmedia.name);
  });
  return (
    <div className="flex justify-end">
      {filteredSocialmedia.map((socialmedialink) => {
        return (
          <a
            href={socialmedialink.link}
            className=" bg-black mr-1 my-1 p-2 rounded-md hover:opacity-70"
            key={socialmedialink.link}
          >
            {socialmedialink.icon}
          </a>
        );
      })}
    </div>
  );
};

export const getCopyright = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="py-[10px] border-t-[1px] border-t-[#b3b3b3] mt-[3%]">
      <p className="text-center text-[#666] text-[13px]">
        {currentYear} ©Copyright entomo (formerly KPISOFT) All Rights Reserved
      </p>
    </div>
  );
};
export const getStar = (limit) => {
  var rows = [];
  for (let i = 0; i < limit; i++) {
    rows.push(<AiFillStar key={i} />);
  }
  return <div className="flex">{rows}</div>;
};

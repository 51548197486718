import React from "react";
import PropTypes from "prop-types";

const Container = ({ children, className = "" }) => {
  return <div className={`mx-auto px-[24px] lg:px-[80px] xl:px-[120px] ${className}`}>{children}</div>;
};

export default Container;

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

import { Link } from "gatsby";
import React from "react";
import PropTypes from "prop-types";

export default function NavMenuRequestDemo({ text, url }) {
  return (
    <Link to={url}>
      <div className="px-3 py-2 rounded-full bg-[#0064BF] text-white text-md font-semibold">{text}</div>
    </Link>
  );
}

NavMenuRequestDemo.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
};

import { Link } from "gatsby";
import React from "react";
import PropTypes from "prop-types";

export default function NavMenuDropdown({ item, selected }) {
  return (
    <div
      className="absolute bg-red-10 w-[70%] left-[28%] top-[50px] lg:top-[65px] xl:top-[80px] 2xl:top-[90px] cursor-default opacity-0 duration-1000 h-0 overflow-hidden"
      style={{
        opacity: selected?.name === item.name ? 1 : 0,
        height: selected?.name === item.name ? "auto" : 0,
      }}
    >
      <div className="bg-[#FBFBFB] p-10 mt-3 flex justify-between whitespace-normal">
        <div className="flex text-left ">
          <div className="w-[320px] border-r-2 pr-10">
            <img src={item.img} alt={item.name} width={320} />
            {item.title ? <div className="mt-5 font-semibold text-3xl">{item.title}</div> : null}
            {item.description ? (
              <div className="mt-4 font-normal text-base text-[#808080]">{item.description}</div>
            ) : null}
            {item.action ? (
              <Link to={item.action.url || "#"}>
                <div className="mt-6 text-base font-semibold text-[#4285f4]">{item.action.name}</div>
              </Link>
            ) : null}
          </div>
          <div className="w-[50%] pl-10">
            {item.sub.map((subItem) => {
              return (
                <Link to={subItem.url || "#"} key={subItem.name}>
                  <div className="mb-5">
                    <div className="font-base text-sm">{subItem.name}</div>
                    {subItem.description ? <div className="text-sm font-normal">{subItem.description}</div> : null}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

NavMenuDropdown.propTypes = {
  item: PropTypes.shape,
  selected: PropTypes.shape({
    name: PropTypes.string,
  }),
};

import React, { useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { FaChevronDown } from "react-icons/fa";
import NavMenuDropdown from "../components/NavMenuDropdown";

export default function NavMenu({ menuItems }) {
  const [selected, setSelected] = useState(null);

  return (
    <div className="flex items-center justify-end flex-wrap">
      <div className="max-w-6xl mx-auto px-4"></div>
      {menuItems.map((item) => {
        return (
          <button
            className="ml-4"
            key={item.name}
            onMouseEnter={() => setSelected(item)}
            onMouseLeave={() => setSelected(null)}
          >
            <div className="flex align-center items-center justify-between text-[#4d4d4d] text-base font-semibold whitespace-nowrap">
              {item.url ? (
                <>
                  {item.urlType === "external" ? (
                    <a href={item.url}>{item.component}</a>
                  ) : (
                    <Link to={item.url}>{item.component}</Link>
                  )}
                </>
              ) : (
                item.component
              )}
              {item.sub === undefined ? null : (
                <>
                  <FaChevronDown className="ml-1 text-xs" />
                  <NavMenuDropdown item={item} selected={selected} />
                </>
              )}
            </div>
          </button>
        );
      })}
    </div>
  );
}

NavMenu.propTypes = {
  menuItems: PropTypes.array,
};
